import styled from 'styled-components';

interface IconElProps {
  height?: number;
}

const IconEl = styled.div<IconElProps>`
  margin: 0;
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
`;

interface IconProps {
  size?: number;
  fill?: string;
}

export const UCWIcon = (props: IconProps) => {
  const { size, fill } = props;
  return (
    <IconEl height={size}>
      <svg width={size || 16} height={size || 16} viewBox='0 0 620 620' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M148.028 338.085L223.386 338.077C233.643 338.076 243.729 340.704 252.681 345.711L296.88 370.432C305.832 375.439 315.915 378.067 326.172 378.066C347.721 378.063 376.056 378.06 384.533 378.059C398.033 378.058 412.034 393.056 412.036 405.556C412.037 414.428 409.015 425.315 399.038 430.889M272.539 434.572C301.706 434.568 364.639 434.561 383.039 434.559C389.715 434.559 394.958 433.168 399.038 430.889M146.044 479.085L156.115 479.084C163.637 479.084 171.063 480.78 177.838 484.047L251.98 519.8C279.452 533.047 311.142 534.315 339.557 523.232C409.077 496.113 536.484 446.252 545.54 441.542C558.039 435.04 558.038 425.04 558.037 416.54C558.036 408.04 552.034 389.041 531.534 389.043C515.134 389.045 436.37 416.941 399.038 430.889' stroke={fill || '#0C9CD8'} strokeWidth='20' strokeLinecap='round' />
        <path d='M61.0261 318.095L115.026 318.089C131.595 318.087 145.028 331.517 145.029 348.086L145.043 472.086C145.045 488.654 131.615 502.087 115.046 502.089L61.0463 502.095L61.0261 318.095Z' stroke={fill || '#0C9CD8'} strokeWidth='20' strokeLinejoin='round' />
        <circle cx='103.031' cy='359.09' r='9' fill={fill || '#0C9CD8'} />
        <path fillRule='evenodd' clipRule='evenodd' d='M364.259 79.1153C343.554 79.1176 322.475 88.9164 310.448 109.564C298.369 88.825 277.344 79.0308 256.696 79.0331C226.781 79.0364 197.668 99.6008 197.672 137.245C197.677 181.066 250.039 225.887 310.467 285.862C370.891 225.873 423.234 181.041 423.229 137.22C423.225 99.5103 394.136 79.112 364.259 79.1153Z' stroke={fill || '#0C9CD8'} strokeWidth='20' strokeLinejoin='round' />
      </svg>
    </IconEl>
  );
};

export const VAWGIcon = (props: IconProps) => {
  const { size, fill } = props;
  return (
    <IconEl height={size}>
      <svg width={size || 16} height={size || 16} viewBox='0 0 620 620' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M310 97C219.978 97 147 169.978 147 260C147 344.965 212.009 414.747 295 422.319V460.5H260.5V489.5H295V522.5H322.5V489.5H358V460.5H322.5V422.528C406.682 416.144 473 345.817 473 260C473 169.978 400.022 97 310 97Z' stroke={fill || '#590F6B'} strokeWidth='20' strokeLinejoin='round' />
        <path d='M310 385C240.964 385 185 329.036 185 260C185 234.407 192.681 210.637 205.862 190.834L378.672 364.465C358.968 377.446 335.381 385 310 385ZM310 135C379.036 135 435 190.964 435 260C435 285.634 427.295 309.438 414.075 329.26L239.256 156.93C259.37 143.096 283.725 135 310 135Z' stroke={fill || '#590F6B'} strokeWidth='20' strokeLinejoin='round' />
        <path d='M274 189.5H328.676C332.48 189.5 336.204 190.585 339.413 192.627L385.738 222.106C391.506 225.777 395 232.141 395 238.979V286.5C395 292.795 392.036 298.723 387 302.5V302.5M252 292.5L240.18 280.828C224.728 265.569 224.872 240.579 240.5 225.5V225.5M252 292.5H303.5M252 292.5V313.5C252 324.546 260.954 333.5 272 333.5H323.213C327.006 333.5 330.736 332.519 334.038 330.652L340.5 327M274 259H256' stroke={fill || '#590F6B'} strokeWidth='20' strokeLinecap='round' />
      </svg>
    </IconEl>
  );
};

export const WESIcon = (props: IconProps) => {
  const { size, fill } = props;
  return (
    <IconEl height={size}>
      <svg width={size || 16} height={size || 16} viewBox='0 0 620 620' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M149 332H224.358C234.614 332 244.7 334.629 253.651 339.637L297.849 364.363C306.8 369.371 316.882 372 327.139 372C348.688 372 377.023 372 385.5 372C399 372 413 387 413 399.5C413 408.372 409.978 419.259 400 424.831M273.5 428.5C302.667 428.5 365.6 428.5 384 428.5C390.676 428.5 395.92 427.11 400 424.831M147 473H157.071C164.593 473 172.019 474.697 178.794 477.965L252.931 513.726C280.402 526.976 312.092 528.248 340.508 517.167C410.031 490.056 537.444 440.209 546.5 435.5C559 429 559 419 559 410.5C559 402 553 383 532.5 383C516.1 383 437.333 410.887 400 424.831' stroke={fill || '#8CA645'} strokeWidth='20' strokeLinecap='round' />
        <path d='M62 312H116C132.569 312 146 325.431 146 342V466C146 482.569 132.569 496 116 496H62V312Z' stroke={fill || '#8CA645'} strokeWidth='20' strokeLinejoin='round' />
        <circle cx='104' cy='353' r='9' fill={fill || '#8CA645'} />
        <circle cx='353' cy='201' r='116' stroke={fill || '#8CA645'} strokeWidth='20' />
        <path d='M355 253V273' stroke={fill || '#8CA645'} strokeWidth='20' strokeLinecap='round' />
        <path d='M355 129L355 149' stroke={fill || '#8CA645'} strokeWidth='20' strokeLinecap='round' />
        <path d='M323 228.385C323 229.93 323 231.475 323 233C323 244.046 331.954 253 343 253H363C374.046 253 383 244.046 383 233V221.923C383 210.877 374.046 201.923 363 201.923H343C331.954 201.923 323 192.969 323 181.923V169C323 157.954 331.954 149 343 149H363C374.046 149 383 157.954 383 169V171.154' stroke={fill || '#8CA645'} strokeWidth='20' strokeLinecap='round' />
      </svg>
    </IconEl>
  );
};
